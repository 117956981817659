var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { display: "inline-block", "margin-left": "10px" } },
    [
      _c(
        "el-button",
        _vm._g(
          {
            attrs: { size: "small", type: "success" },
            on: { click: _vm.open }
          },
          _vm.$attrs
        ),
        [_vm._t("default", [_vm._v("导入")])],
        2
      ),
      _c("Dialog", {
        ref: "Dialog",
        attrs: {
          downloadUrl: _vm.downloadUrl,
          uploadUrl: _vm.uploadUrl,
          fileType: _vm.fileType,
          fileName: _vm.fileName
        },
        on: {
          refresn: function($event) {
            return _vm.$emit("refresh")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }