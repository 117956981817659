//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Dialog from './index.vue';
export default {
  props: {
    downloadUrl: {
      type: String,
      required: true },

    uploadUrl: {
      type: String,
      required: true },

    fileType: {
      type: String,
      required: true },

    fileName: {
      type: String },

    data: {
      type: Object,
      "default": null } },


  components: { Dialog: Dialog },
  methods: {
    open: function open() {
      this.$refs.Dialog.open();
    } } };